import React, { Suspense } from 'react';
import Sidebar from "./Sidebar";
import styles from './layout.module.scss'
import { SwipeableDrawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../redux/reducers/common";
import Explore from "../../../containers/Explore";
import MainLoader from "../../Loader/MainLoader";
import WebsocketProvider from "../../../Providers/WebsocketProvider";
import { useValidateLogin } from '../../../hooks/auth';
import { PRODUCT_FRUITS_WORKSPACE_CODE } from '../../../utils/constants';
import sha256 from 'crypto-js/sha256';
import { ProductFruits } from 'react-product-fruits';
import { siembaRoles } from '../../../utils/roles';
import { customerRoles } from '../../../utils/constants/customerRoles';

const AddCustomerModal = React.lazy(() => import("./AddCustomerModal"))
const AddUsersModal = React.lazy(() => import("./AddUsersModal"))
const DecommissionAssetsModal = React.lazy(() => import("./DecommissionAssetsModal"))
const RecommissionAssetsModal = React.lazy(() => import("./RecommissionAssetsModal"))
const DeleteAssetsModal = React.lazy(() => import("./DeleteAssetsModal"))
const AddNewAssetsModal = React.lazy(() => import("./AddNewAssetsModal"))
const EditAssetsModal = React.lazy(() => import("./EditAssetModal/index.js"))
const AddFindingsModal = React.lazy(() => import("./AddFindingsModal"))
const NewPentestModal = React.lazy(() => import("./NewPentestModal"))
const AddBlockerModal = React.lazy(() => import("./AddBlockerModal"))
const KeyInsightModal = React.lazy(() => import("./KeyInsightModal"))
const ReportModal = React.lazy(() => import("./ReportModal"))
const InviteCustomerModal = React.lazy(() => import("./InviteCustomerModal"))
const ImportAssetsModal = React.lazy(() => import("./ImportAssetsModal"))
const SeverityChangeModal = React.lazy(() => import("./SeverityChangeModal"))

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const { openDrawer, expandDrawer } = useSelector(state => state.common)
    const { addCustomerModal } = useSelector(state => state.customer)
    const {
        addUsersModal,
        decommissionAssetsModal,
        recommissionAssetsModal,
        deleteAssetsModal,
        addNewAssetsModal,
        addFindingsModal,
        newPentestModal,
        addBlockerModal,
        keyInsightsModal,
        reportModal,
        inviteCustomerModal,
        importAssetsModal,
        editAssetsModal,
        severityChangeModal
    } = useSelector(state => state.dashboard)

    const { data } = useValidateLogin()

    return (
        <div>
            {PRODUCT_FRUITS_WORKSPACE_CODE ? <ProductFruits workspaceCode={PRODUCT_FRUITS_WORKSPACE_CODE} language="en" user={{
                username: sha256(data?.user?.uuid).toString(),
                firstname: data?.user?.first_name,
                lastname: data?.user?.last_name,
                role: Object.keys(siembaRoles).includes(data?.user?.role) ? "S".concat("_", siembaRoles[data?.user?.role]?.priority.toString())
                    : Object.keys(customerRoles).includes(data?.user?.role) ? "C".concat("_", customerRoles[data?.user?.role]?.priority.toString()) : null,
                signUpAt: data?.user?.created_at
            }} /> : undefined}
            <WebsocketProvider>
                <Sidebar />
                <Explore />
                <SwipeableDrawer
                    open={openDrawer}
                    onClose={() => dispatch(setDrawer(false))}
                    onOpen={() => dispatch(setDrawer(true))}
                >
                    <div className={styles.sidebarDrawer}>
                        <Sidebar from={"drawer"} />
                    </div>
                </SwipeableDrawer>
                <div id={"dashboardMainContent"} className={`${styles.main} ${!expandDrawer ? styles.expandMain : ''}`}>
                    {children}
                </div>
                <Suspense fallback={<MainLoader />}>
                    {addCustomerModal && <AddCustomerModal />}
                    {addUsersModal?.open && <AddUsersModal />}
                    {decommissionAssetsModal?.open && <DecommissionAssetsModal />}
                    {recommissionAssetsModal?.open && <RecommissionAssetsModal />}
                    {deleteAssetsModal?.open && <DeleteAssetsModal />}
                    {addNewAssetsModal?.open && <AddNewAssetsModal />}
                    {editAssetsModal?.open && <EditAssetsModal />}
                    {addFindingsModal?.open && <AddFindingsModal />}
                    {newPentestModal?.open && <NewPentestModal />}
                    {addBlockerModal?.open && <AddBlockerModal />}
                    {keyInsightsModal?.open && <KeyInsightModal />}
                    {reportModal?.open && <ReportModal reportPreview={reportModal?.report} user={data.user} />}
                    {inviteCustomerModal?.open && <InviteCustomerModal />}
                    {importAssetsModal?.open && <ImportAssetsModal />}
                    {severityChangeModal?.open && <SeverityChangeModal />}
                </Suspense>
            </WebsocketProvider>
        </div>
    );
};

export default Layout;
