import React from 'react';

const AllBlockersIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={"inline-block"}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.8995 4H7.18176C6.49111 4 5.93359 4.55767 5.93359 5.24817C5.93359 5.93882 6.49126 6.49634 7.18176 6.49634H19.8954C20.5861 6.49634 21.1436 5.93867 21.1436 5.24817C21.1436 4.55767 20.5903 4 19.8996 4H19.8995Z"
                fill="white"/>
            <path
                d="M19.8995 10.254H7.18176C6.49111 10.254 5.93359 10.8117 5.93359 11.5022C5.93359 12.1928 6.49126 12.7504 7.18176 12.7504H19.8954C20.5861 12.7504 21.1436 12.1927 21.1436 11.5022C21.1436 10.8115 20.5903 10.254 19.8996 10.254H19.8995Z"
                fill="white"/>
            <path
                d="M19.8995 16.5039H7.18176C6.49111 16.5039 5.93359 17.0616 5.93359 17.7521C5.93359 18.4427 6.49126 19.0002 7.18176 19.0002H19.8954C20.5861 19.0002 21.1436 18.4426 21.1436 17.7521C21.1436 17.0614 20.5903 16.5039 19.8996 16.5039H19.8995Z"
                fill="white"/>
            <path
                d="M3.23957 4.02148C2.55752 4.02148 2 4.57915 2 5.26105C2 5.9431 2.55767 6.50062 3.23957 6.50062C3.92161 6.50062 4.47913 5.94295 4.47913 5.26105C4.47928 4.579 3.92161 4.02148 3.23957 4.02148Z"
                fill="white"/>
            <path
                d="M3.23957 10.2627C2.55752 10.2627 2 10.8204 2 11.5023C2 12.1843 2.55767 12.7418 3.23957 12.7418C3.92161 12.7418 4.47913 12.1842 4.47913 11.5023C4.47928 10.8202 3.92161 10.2627 3.23957 10.2627Z"
                fill="white"/>
            <path
                d="M3.23957 16.5039C2.55752 16.5039 2 17.0616 2 17.7435C2 18.4255 2.55767 18.983 3.23957 18.983C3.92161 18.983 4.47913 18.4254 4.47913 17.7435C4.47928 17.0614 3.92161 16.5039 3.23957 16.5039Z"
                fill="white"/>
        </svg>
    );
};

export default AllBlockersIcon;
