import MonitorIcon from "@mui/icons-material/Monitor";
import React from "react";
import ApiIcon from "../../components/SvgIcons/ApiIcon";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import FlightIcon from "../../components/SvgIcons/FlightIcon";
import ExternalIcon from "../../components/SvgIcons/ExternalIcon";
import InternalIcon from "../../components/SvgIcons/InternalIcon";
import CloudIcon from "../../components/SvgIcons/CloudIcon";
import DraftIcon from "../../components/SvgIcons/DraftIcon";
import CompletedIcon from "../../components/SvgIcons/CompletedIcon";
import RemediatingIcon from "../../components/SvgIcons/RemediatingIcon";
import RetestReadyIcon from "../../components/SvgIcons/RetestReadyIcon";
import RetestFailedIcon from "../../components/SvgIcons/RetestFailedIcon";
import ReportReadyIcon from "../../components/SvgIcons/ReportReadyIcon";
import ReportDeliveredIcon from "../../components/SvgIcons/ReportDeliveredIcon";
import { API, MOBILE_APP, WEB_APP } from "../../containers/pages/PentestScope/AddNewAssets/utils/asset_constants";

export const platforms = {
    web_app: {
        title: "Web App",
        fullTitle: "Web Application",
        icon: (<MonitorIcon className={"text-primary-500 align-top"} />)
    },
    api: {
        title: "API",
        icon: <ApiIcon />
    },
    mobile_app: {
        title: "Mobile App",
        fullTitle: "Mobile Application",
        icon: <PhoneAndroidIcon className={"text-tertiary-600"} />
    },
    it_infra: {
        title: "IT Infrastructure",
        icon: <DeviceHubIcon className={"text-primary-500"} />
    },
    cloud: {
        title: "Cloud",
        icon: <CloudIcon />
    },
    wordpress: {
        title: "Wordpress",
        icon: <CloudIcon />
    }
}

export const stages = {
    pending: {
        title: "Pre - Requisites",
        icon: <DraftIcon />
    },
    inflight: {
        title: "Inflight",
        icon: <FlightIcon />
    },
    testing_completed: {
        title: "Testing Completed",
        icon: <CompletedIcon />
    },
    remediation: {
        title: "Remediating",
        icon: <RemediatingIcon />
    },
    retest_ready: {
        title: "Retest Ready",
        icon: <RetestReadyIcon />
    },
    retest_failed: {
        title: "Retest Failed",
        icon: <RetestFailedIcon />
    },
    report_ready: {
        title: "Report Ready",
        icon: <ReportReadyIcon />
    },
    report_delivered: {
        title: "Report Delivered",
        icon: <ReportDeliveredIcon />
    }
}

export const testing_types = {
    greybox: {
        title: "Grey Box",
    },
    blackbox: {
        title: "Black Box"
    },
    whitebox: {
        title: "White Box"
    }
}
export const modes = {
    internal: {
        title: "Internal",
        icon: <InternalIcon />
    },
    external: {
        title: "External",
        icon: <ExternalIcon />
    },
}

export const os_type = {
    android: {
        title: "Android"
    },
    ios: {
        title: "iOS"
    },
    windows: {
        title: "Windows"
    },
}

export const pentestStatusColors = {
    inflight: {
        name: 'Inflight',
        color: "var(--primary-500)"
    },
    draft: {
        name: 'Draft',
        color: "var(--tertiary-600)"
    },
    retest: {
        name: 'Retest',
        color: "var(--functional-purple)"
    },
    upcoming: {
        name: 'Upcoming',
        color: "var(--secondary-700)"
    },
    closed: {
        name: 'Closed',
        color: "var(--grey2)"
    },
}


export const assetStages = {
    prerequisites: {
        key: "prerequisites",
        title: "Pre - Requisites",
        icon: <DraftIcon />
    },
    testing: {
        key: "testing",
        title: "Testing",
        icon: <FlightIcon />
    },
    remediation: {
        key: "remediation",
        title: "Remediation",
        icon: <RemediatingIcon />
    },
    final_report: {
        key: "final_report",
        title: "Final Report",
        icon: <ReportReadyIcon />
    }
}

export const EDIT_ROLES_PLATFORM = [WEB_APP, API, MOBILE_APP];