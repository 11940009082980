import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    addUsersModal: {
        open: false,
    },
    addNewAssetsModal: {
        open: false,
        company_id: null
    },
    editAssetsModal: {
        open: false,
        company_id: null,
        asset: null
    },
    decommissionAssetsModal: {
        open: false,
        selectedRows: null,
    },
    deleteAssetsModal: {
        open: false,
        selectedRows: null
    },
    recommissionAssetsModal: {
        open: false,
        selectedRows: null
    },
    addFindingsModal: {
        open: false,
        asset_id: null,
        finding_id: null,
        progress: null
    },
    newPentestModal: {
        open: false,
        pentest_id: null,
        progress: null,
        latest_progress: null
    },
    addBlockerModal: {
        open: false,
        pentest_asset_id: null,
        id: null,
        pentestAsset: null,
        pentest_id: null,
        generalBlocker: false,
    },
    updatePentestLandscape: false,
    explore: {
        dashboard: null,
        id: null
    },
    keyInsightsModal: {
        open: false,
        id: null
    },
    preRequisitesData: {},
    preRequisitesMetaData: {},
    preRequisitesEditCategory: null,
    preRequisitesAdditionalQueries: [],
    reportModal: {
        open: false,
        pentest_asset_id: null,
        report_detail: {}
    },
    customFindingsReportModal: {
        open: false,
    },
    inviteCustomerModal: {
        open: false,
    },
    importAssetsModal: {
        open: false,
    },
    severityChangeModal: {
        open: false,
        severity_change:{},
        justification:null,
        is_justification_submitted: false,
        edit_mode: false,
        finding_id: null
    },
    GPTSession: {
        session_id: null,
        asset_type: null,
        target_url: null,
    }
}
export const authSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setAddUsersModal: (state, action) => {
            return {
                ...state,
                addUsersModal: {
                    ...state.addUsersModal,
                    ...action.payload
                }
            }
        },
        setAddNewAssetsModal: (state, action) => {
            return {
                ...state,
                addNewAssetsModal: {
                    ...state.addNewAssetsModal,
                    ...action.payload
                }
            }
        },
        setEditAssetsModal: (state, action) => {
            return {
                ...state,
                editAssetsModal: {
                    ...state.editAssetsModal,
                    ...action.payload,
                }
            }
        },
        setDecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                decommissionAssetsModal: {
                    ...state.decommissionAssetsModal,
                    ...action.payload
                }
            }
        },
        setDeleteAssetsModal: (state, action) => {
            return {
                ...state,
                deleteAssetsModal: {
                    ...state.deleteAssetsModal,
                    ...action.payload
                }
            }
        },
        setRecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                recommissionAssetsModal: {
                    ...state.recommissionAssetsModal,
                    ...action.payload
                }
            }
        },
        setAddFindingsModal: (state, action) => {
            return {
                ...state,
                addFindingsModal: {
                    ...state.addFindingsModal,
                    ...action.payload
                }
            }
        },
        setNewPentestModal: (state, action) => {
            return {
                ...state,
                newPentestModal: {
                    ...state.newPentestModal,
                    ...action.payload
                }
            }
        },
        setAddBlockerModal: (state, action) => {
            return {
                ...state,
                addBlockerModal: {
                    ...state.addBlockerModal,
                    ...action.payload
                }
            }
        },
        setKeyInsightsModal: (state, action) => {
            return {
                ...state,
                keyInsightsModal: {
                    ...state.keyInsightsModal,
                    ...action.payload
                }
            }
        },
        closeUsersModal: (state, action) => {
            return {
                ...state,
                addUsersModal: initialState.addUsersModal
            }
        },
        closeNewAssetsModal: (state, action) => {
            return {
                ...state,
                addNewAssetsModal: initialState.addNewAssetsModal
            }
        },
        closeEditAssetsModal: (state, action) => {
            return {
                ...state,
                editAssetsModal: initialState.editAssetsModal
            }
        },
        closeDecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                decommissionAssetsModal: initialState.decommissionAssetsModal
            }
        },
        closeDeleteAssetsModal: (state, action) => {
            return {
                ...state,
                deleteAssetsModal: initialState.deleteAssetsModal
            }
        },
        closeRecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                recommissionAssetsModal: initialState.recommissionAssetsModal
            }
        },
        closeFindingsModal: (state, action) => {
            return {
                ...state,
                addFindingsModal: initialState.addFindingsModal
            }
        },
        closeKeyInsightsModal: (state) => {
            return {
                ...state,
                keyInsightsModal: initialState.keyInsightsModal
            }
        },
        closePentestModal: (state, action) => {
            return {
                ...state,
                newPentestModal: initialState.newPentestModal
            }
        },
        closeBlockerModal: (state, action) => {
            return {
                ...state,
                addBlockerModal: initialState.addBlockerModal
            }
        },
        explore: (state, action) => {
            return {
                ...state,
                explore: {
                    ...state.explore,
                    ...action.payload
                }
            }
        },
        closeExplore: (state) => {
            return {
                ...state,
                explore: initialState.explore
            }
        },
        setUpdatePentestLandscape: (state, action) => {
            return {
                ...state,
                updatePentestLandscape: action.payload
            }
        },
        setPreRequisitesData: (state, action) => {
            return {
                ...state,
                preRequisitesData: action.payload
            }
        },
        setDashboardInputs: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        setReportModal: (state, action) => {
            return {
                ...state,
                reportModal: action.payload
            }
        },
        closeReportModal: (state, action) => {
            return {
                ...state,
                reportModal: initialState.reportModal
            }
        },
        setCustomFindingsReportModal: (state, action) => {
            return {
                ...state,
                customFindingsReportModal: action.payload
            }
        },
        closeCustomFindingsReportModal: (state, action) => {
            return {
                ...state,
                customFindingsReportModal: initialState.customFindingsReportModal
            }
        },
        setInviteCustomerModal: (state, action) => {
            return {
                ...state,
                inviteCustomerModal: action.payload
            }
        },
        closeInviteCustomerModal: (state, action) => {
            return {
                ...state,
                inviteCustomerModal: initialState.inviteCustomerModal
            }
        },
        setImportAssetsModal: (state, action) => {
            return {
                ...state,
                importAssetsModal: action.payload
            }
        },
        closeImportAssetsModal: (state, action) => {
            return {
                ...state,
                importAssetsModal: initialState.importAssetsModal
            }
        },
        setSeverityChangeModal: (state, action) => {
            return {
                ...state,
                severityChangeModal: {
                    ...state.severityChangeModal,
                    ...action.payload
                }
            }
        },
        closeSeverityChangeModal: (state, action) => {
            return {
                ...state,
                severityChangeModal: initialState.severityChangeModal
            }
        },
        setGPTSession: (state, action) => {
            return {
                ...state,
                GPTSession: action.payload
            }
        },
        clearGPTSession: (state, action) => {
            return {
                ...state,
                GPTSession: initialState.GPTSession
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setDecommissionAssetsModal,
    closeDecommissionAssetsModal,
    setRecommissionAssetsModal,
    closeRecommissionAssetsModal,
    setDeleteAssetsModal,
    closeDeleteAssetsModal,
    setAddUsersModal,
    closeUsersModal,
    setAddFindingsModal,
    closeFindingsModal,
    explore,
    closeExplore,
    setNewPentestModal,
    closePentestModal,
    setAddBlockerModal,
    closeBlockerModal,
    setUpdatePentestLandscape,
    setKeyInsightsModal,
    closeKeyInsightsModal,
    setPreRequisitesData,
    setDashboardInputs,
    setReportModal,
    closeReportModal,
    setCustomFindingsReportModal,
    closeCustomFindingsReportModal,
    setInviteCustomerModal,
    closeInviteCustomerModal,
    setAddNewAssetsModal,
    closeNewAssetsModal,
    setImportAssetsModal,
    closeImportAssetsModal,
    setEditAssetsModal,
    closeEditAssetsModal,
    setSeverityChangeModal,
    closeSeverityChangeModal,
    setGPTSession,
    clearGPTSession
} = authSlice.actions

export default authSlice.reducer
