import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './layout.module.scss'
import {Link, useLocation} from "react-router-dom";
import { getAllDataFromPages} from '../../../actions/commonActions';
import { useConversations } from '../../../hooks/chat';
import menu from "./menus/menu";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {setDrawer, setExpandDrawer} from "../../../redux/reducers/common";
import {LayoutContext} from "../../../Providers/LayoutProvider";
import my_dashboard_menu from "./menus/my_dashboard_menu";
import tester_dashboard_menu from "./menus/tester_dashboard_menu";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {PermissionProvider} from "../../../hoc/withPermissions";
import SidebarExpandIcon from '../../SvgIcons/SidebarExpandIcon';
import { SIEMBAGPT_ENABLED } from '../../../utils/constants';

const Sidebar = ({ from }) => {

    const collapseBtnRef = useRef();
    const [collapseIndex, setCollapseIndex] = useState(null)
    let expandSidebarVal = true;    
    const sessionValue = window.sessionStorage;
    if (sessionValue.getItem("drawerCollapsed") === "true") {
        expandSidebarVal = false;
    }

    const [expandSidebar, updateExpandSidebar] = useState(expandSidebarVal);
    const [expandHoverSidebar, updateExpandHoverSidebar] = useState(false);
    const {pathname: path} = useLocation();
    const {
        data: personalData    } = useConversations({
        type: "personal",
        enabled: true,
        search: ""
    })
    const {
        data: channelData    } = useConversations({
        type: "channels",
        enabled: true,
        search: ""
    })

    let totalUnreadCount = 0;

    totalUnreadCount =  personalData?.pages[0]?.totalUnreadCount  +  channelData?.pages[0]?.totalUnreadCount
        
    const dispatch = useDispatch()
    const { expandDrawer } = useSelector(state => state.common)
    const value = useContext(LayoutContext)
    let menu = getMenu(value.dashboard)
    if (SIEMBAGPT_ENABLED === 'false' || SIEMBAGPT_ENABLED === '') {
        menu = menu.filter(obj => obj.path !== "/admin/genpt");
    }
    const dashboardRoute = getDashboardRoute(value.dashboard)
    useEffect(() => {
        menu?.forEach((menuItem, i) => {
            let isChild = menuItem?.child?.some(el => path.startsWith(el?.path))
            if (isChild)
                setCollapseIndex(i)
        })
    }, [])
    const renderSingleMenu = (singleMenu, index) => {
        return (
            <div className='flex items-center'>
                <div className={"inline-block w-[39px]"}>
                    {singleMenu?.icon && <singleMenu.icon/>}
                </div>
                {singleMenu.label} {
                    singleMenu.label == "Chat" &&
                    totalUnreadCount > 0 &&
                    <span
                            className={"bg-white py-[2px] px-[8px] text-secondary-700 text-xs rounded-full mx-[15px] my-[3px] font-semibold"} > {totalUnreadCount} </span>

                    ||
                    singleMenu?.child &&
                    <>
                        {
                            index === collapseIndex ?
                                <ExpandLessIcon className={"!align-bottom"}/> :
                                <ExpandMoreIcon className={"!align-bottom"}/>
                        }
                    </>
                }
            </div>
        )
    }
    
    const renderMenu = (items, child = false) => {
        return items?.map((item, index) => {
            let active = false, parentActive = false;
            if (item?.child?.some(el => path.startsWith(el?.path))) {
                parentActive = true
            }
            active = path.startsWith(item.path)

            return (
                <PermissionProvider key={index} roles={item?.roles}>
                    <li
                        className={`${active && styles.active} ${parentActive && styles.parentActive} 
                        ${child && styles.child}
                    ` || undefined}>
                        {
                            <>
                                {
                                    item?.path ?
                                        <Link onClick={() => {
                                            dispatch(setDrawer(false))
                                            if (!child)
                                                setCollapseIndex(null)
                                        }} to={item?.path}>
                                            {renderSingleMenu(item)}
                                        </Link> :
                                        <div onClick={() => setCollapseIndex(collapseIndex ? null : index)}
                                            className={styles.menu}>
                                            {renderSingleMenu(item, index)}
                                        </div>
                                }
                                {
                                    item?.child &&
                                    <Collapse in={collapseIndex === index}>
                                        <ul>
                                            {renderMenu(item.child, true)}
                                        </ul>
                                    </Collapse>
                                }
                            </>
                        }

                    </li>
                </PermissionProvider>
            )
        })
    }

    return (
        <div 
            className={`${from === "drawer" ? "" : styles.sidebar} no-scrollbar ${expandSidebar || expandHoverSidebar ? styles.sidebarExpanded : styles.sidebarCollapse}`}
            onMouseEnter={(e) => {
                if (!e.target.className.includes("collapse-ele")) {
                    if (!expandSidebar) {
                        updateExpandHoverSidebar(!expandHoverSidebar);
                        dispatch(setExpandDrawer(!expandDrawer));
                    }
                }
            }}
            onMouseLeave={(e) => {
                if (!expandSidebar && expandDrawer) {
                    updateExpandHoverSidebar(!expandHoverSidebar);
                    dispatch(setExpandDrawer(!expandDrawer));
                }
            }}
        >
            <div className={`${styles.sidebarCollapseBtn} collapse-ele`} ref={collapseBtnRef} onClick={() => {
                if (expandHoverSidebar) {
                    dispatch(setExpandDrawer(true));
                } else {
                    dispatch(setExpandDrawer(!expandDrawer));
                }
                updateExpandSidebar(!expandSidebar);
                updateExpandHoverSidebar(false);
                window.sessionStorage.setItem("drawerCollapsed", expandSidebar);
            }}>
                <span className={`${!expandSidebar || expandHoverSidebar ? styles.collapseIcon : ''} ${styles.expandIcon}`}>
                    <SidebarExpandIcon />
                </span>
            </div>
            <ul id ={"sidebarContainer"}>
                {
                    renderMenu(menu)
                }
            </ul>
        </div>
    );
};

Sidebar.prototype = {
    from: PropTypes.string
}
export default Sidebar;


const getMenu = (dashboard) => {
    switch (dashboard) {
        case 'MY_DASHBOARD':
            return my_dashboard_menu;
        case 'TESTER_DASHBOARD':
            return tester_dashboard_menu;
        case 'ADMIN_DASHBOARD':
        default:
            return menu;
    }
}
const getDashboardRoute = (dashboard) => {
    switch (dashboard) {
        case 'MY_DASHBOARD':
            return '/app';
        case 'TESTER_DASHBOARD':
            return '/tester';
        case 'ADMIN_DASHBOARD':
        default:
            return '/admin';
    }
}
