import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {
    ALL_FINDINGS,
    DRAFT_FINDINGS,
    FINDINGS,
    FINDINGS_STATS,
    FINDING_BREAKDOWN_CHART,
    FINDING_BREAKDOWN_STATUS_CHART,
    LIST_SIEMBA_ASSETS,
    ASSET_MANAGEMENT
} from "./utils/constants";
import {paginationAttr} from "../actions/commonActions";

//queries
export const useFindingsById = (id, from = '', sessionId = '', options = {}) => {
    return useQuery([FINDINGS, id, from , sessionId], async () => {
        let res = null
        if (sessionId) {
            res = await axios.get(`/genpt/sessions/${sessionId}/findings/${id}`)
        } else {
            res = await axios.get(`pentest/findings/${id}`);
            let environment;
            let platform_category = res.data.findingDetails.pentest_asset.asset.platform_category;
            if(platform_category == 'mobile_app' || platform_category == 'web_app'){
                let company_id = res.data.findingDetails.pentest.company_id;
                let pentest_asset_id = res.data.findingDetails.pentest_asset_id;
                //getting env data from pre requisites
                let resEnv = await axios.post(`/pentest_asset/prerequisites/fetch/`, { company_id, pentest_asset_id });
                let envType = resEnv.data.prerequisitesData.prerequisitesGrouped.environment[2];
                // non production check
                if(envType.answer == 'non_production'){
                    // non prod and other
                    if(envType.sub.non_production.answer == 'other' && envType.sub.non_production.sub.other.answer){
                        envType = (envType.sub.non_production.sub.other.answer).toUpperCase();
                    }else{
                        envType = (envType.sub.non_production.answer).toUpperCase();
                    }  
                }else if(envType.answer == 'production'){
                    envType = 'PRODUCTION'
                }else {
                    envType = 'NA'
                }
                environment = envType;
                
            }
            else{
                environment = 'NA';    
            }
            res.data.findingDetails.pentest_asset.environment = environment;      
        }
        return res.data;        
    }, {
        enabled: !!id,
        ...options
    })
}

export const useFindingsStats = (options = {}) => {
    const {
        pentest_id = null,
        pentest_asset_id = null
    } = options
    return useQuery([FINDINGS_STATS, pentest_id, pentest_asset_id], async () => {
        let res = await axios.get(`pentest/findings/dashboard/title${paginationAttr({
            pentest_id, pentest_asset_id
        })}`)
        return res.data;
    }, {
        ...options
    })
}

//mutations
export const useCreateFindings = (options = {}) => {
    
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`pentest/findings/create_or_update_finding`, data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(DRAFT_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS)
            await queryClient.invalidateQueries(ALL_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS_STATS)
            await queryClient.invalidateQueries(LIST_SIEMBA_ASSETS)            
        },
        ...options
    })
}

//mutations
export const useUpdateFindingAttributes = (pentest_asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const payload = {
            pentest_asset_id,
            finding_data: data
        }
        let res = await axios.put(`pentest/findings/update_finding_attributes`, payload)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(DRAFT_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS)
            await queryClient.invalidateQueries(ALL_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS_STATS)
            await queryClient.invalidateQueries(LIST_SIEMBA_ASSETS)
        },
    })
}


export const useBreakdownFindings = (options = {}) => {
    const {
        pentest_id = null,
        pentest_asset_id = null,
        company_id = null
    } = options
    return useQuery([FINDING_BREAKDOWN_CHART, pentest_id, pentest_asset_id], async () => {
        let res = await axios.get(`/dashboard/charts/severity_breakdown${paginationAttr({
            pentest_id, pentest_asset_id, company_id
        })}`)
        return res.data;
    }, {
        ...options
    })
}


export const useBreakdownStatusFindings = (options = {}) => {
    const {
        pentest_id = null,
        pentest_asset_id = null,
        company_id = null
    } = options
    return useQuery([FINDING_BREAKDOWN_STATUS_CHART, pentest_id, pentest_asset_id], async () => {
        let res = await axios.get(`/dashboard/charts/status_breakdown${paginationAttr({
            pentest_id, pentest_asset_id, company_id
        })}`)
        return res.data;
    }, {
        ...options
    })
}

export const useDeleteFinding = () => {
    
    let queryClient = useQueryClient()
    return useMutation(async (finding_id) => {
        let res = await axios.put(`/pentest/findings/delete/${finding_id}`)
        return res;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ALL_FINDINGS)
        }
    })
}


export const useSearchFindingsByAttributes = (options) => {
    return useMutation(async (data) => {
        let payload = {
            data: data
        }
        let res = await axios.post(`pentest/findings/search`, payload)
        return res.data
    }, options)
}

export const useUpdateCvssJustification = (findingId) => {

    return useMutation(async (data) => {
        let res = await axios.put(`pentest/findings/${findingId}/cvss/justification`, data)
        return res.data
    })
}

export const useDeleteCvssJustification = (findingId) => {

    return useMutation(async (data) => {
        let res = await axios.delete(`pentest/findings/${findingId}/cvss/justification`, data)
        return res.data
    })
}
