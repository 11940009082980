import {SIEMBA_ADMIN, SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER, SIEMBA_TESTER} from "../utils/roles";
import {DATE_FORMAT, SIEMBA_CUSTOMER_STAGES_ROUTES} from "../utils/constants";
import Storage from "../utils/Storage";
import {explore} from "../redux/reducers/dashboard";
import store from "../redux/store"
import {
    CUSTOMER_DETAILS,
    EXISTING_ASSETS,
    LIST_ALL_PENTEST_ASSETS,
    LIST_ALL_PENTEST_ASSETS_REVIEW,
    LIST_CUSTOMER_TEAM, LIST_CUSTOMER_TEAM_BY_ROLE,
    LIST_ALL_CUSTOMER_TEAM,
    LIST_CUSTOMER_TEAM_INVITES,
    NEW_ASSETS,
    NEXT_PENTEST_NAME,
    VALIDATE_LOGIN, YOUR_PENTESTS
} from "../hooks/utils/constants";
import moment from "moment";

const dispatch = store.dispatch

/**
 * returns if current user has access and current user's access routes
 * @param roles array of roles who has access
 * @param user current user details
 */
export const getAccessDetails = (roles = [], user) => {
    let userRole = user?.role;
    let hasAccess = false;
    if (roles.length > 0)
        hasAccess = roles.includes(userRole)
    else
        hasAccess = true;
    let mainRoutes = ["/"]
    if (userRole === SIEMBA_ADMIN)
        mainRoutes = ["/admin"]
    if (userRole === SIEMBA_TESTER)
        mainRoutes = ["/tester"]
    if (userRole === SIEMBA_CUSTOMER)
        mainRoutes = ["/app"]
    if (userRole === SIEMBA_CUSTOMER_ENGINEER)
        mainRoutes = ["/app"]
    return {
        hasAccess,
        mainRoutes: mainRoutes,
        ignoredRoutes: ["/connect_siemba"]
    }
}

/**
 * return number with 0 prefix for single digit numbers
 * @param number
 * @returns {string}
 */
export const get2DigitFormattedNumber = (number = 0) => {
    return (number || 0).toLocaleString("en-US", {
        useGrouping: false,
    });
}

export const redirectPathOfCustomerProfileRoutes = (dir, currentScreen, params, fromReview = true) => {
    let screens = Object.keys(SIEMBA_CUSTOMER_STAGES_ROUTES)
    let index = screens.findIndex(el => el === currentScreen)
    const CUSTOMER_PROFILE_STAGE = 'customerProfileStage';
    let path;
    if (dir === 'next') {
        const nextScreen = screens[index + 1]
        if (Storage.getData(CUSTOMER_PROFILE_STAGE, true))
            Storage.setData(CUSTOMER_PROFILE_STAGE, nextScreen, true)
        path = SIEMBA_CUSTOMER_STAGES_ROUTES[nextScreen]
    } else if (dir === 'prev') {
        const prevScreen = screens[index - 1]
        Storage.setData(CUSTOMER_PROFILE_STAGE, prevScreen, true)
        path = SIEMBA_CUSTOMER_STAGES_ROUTES[prevScreen]
    } else if (dir === 'to') {
        path = SIEMBA_CUSTOMER_STAGES_ROUTES[screens[index]]
        Storage.setData(CUSTOMER_PROFILE_STAGE, screens[index], true)
        fromReview && Storage.setData('fromReview', true, true)
    }
    return path + (params ? params : "")
}

export const getAvatarString = (name = "") => {
    let splitValue = name.split(" ")
    return `${splitValue?.[0]?.[0]?.toUpperCase() || ""}${splitValue?.[1]?.[0]?.toUpperCase() || ""}`
}

export const paginationAttr = ({page, pageSize, search, sortBy, ...rest}) => {
    let attr = `?page=${page}&pageSize=${pageSize}${
        (search ? `&searchKey=${search}` : ``)
    }`
    for (const attrKey in rest) {
        if (rest[attrKey])
            attr += `&${attrKey}=${rest[attrKey]}`
    }
    let sortValue = ""
    sortBy?.forEach((item, index) => {
        let sortKey = item.id?.replaceAll('_', '')
        let dir = item.desc ? "desc" : "asc"
        sortValue += `${index > 0 ? "," : ""}${sortKey}_${dir}`
    })
    if (sortValue)
        attr += `&sort=${sortValue}`

    return attr
}

export const getAllDataFromPages = (pages, key) => {
    let data = []
    pages?.forEach(item => {
        let keyData = item[key] || []
        data = [...data, ...keyData]
    })
    return data || [];
}

export const exploreDashboard = (dashboard, id, role=null, currentActiveTab=null, navigate) => {
    if (window.innerWidth <= 640)
        return navigate()
    let payload = {
        id,
        dashboard,
        role,
        currentActiveTab
    }
    dispatch(explore(payload))
}

export const invalidatePentestQueries = async (queryClient) => {
    await Promise.all([
        queryClient.invalidateQueries(VALIDATE_LOGIN),
        queryClient.invalidateQueries(CUSTOMER_DETAILS),
        queryClient.invalidateQueries(NEXT_PENTEST_NAME),
        queryClient.invalidateQueries(EXISTING_ASSETS),
        queryClient.invalidateQueries(NEW_ASSETS),
        queryClient.invalidateQueries(LIST_CUSTOMER_TEAM_INVITES),
        queryClient.invalidateQueries(LIST_CUSTOMER_TEAM),
        queryClient.invalidateQueries(LIST_ALL_CUSTOMER_TEAM),
        queryClient.invalidateQueries(LIST_ALL_PENTEST_ASSETS),
        queryClient.invalidateQueries(LIST_ALL_PENTEST_ASSETS_REVIEW),
        queryClient.invalidateQueries(LIST_CUSTOMER_TEAM_BY_ROLE),
        queryClient.invalidateQueries(YOUR_PENTESTS),
    ])
}

export const getLatestPentestProgress = (progress, current) => {
    const progresses = ["pentest_company", "pentest_profile", "pentest_scope", "pentest_team", "pentest_review"]
    const progressIndex = progresses?.indexOf(progress)
    const currentIndex = progresses?.indexOf(current)
    const latestIndex = currentIndex >= progressIndex ? currentIndex : progressIndex
    return progresses[latestIndex]
}

export const areEqualProps = (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

export const relativeTimeFormat = (timestamp) => {
    if (!timestamp)
        return undefined;
    let time = moment.unix(timestamp / 1000)
    const dayDiff = moment().diff(time, "day")
    let formattedTime = time.format("LT")
    if (dayDiff > 0)
        formattedTime = time.format(DATE_FORMAT)
    return formattedTime
}
