import React, { useEffect, useState } from 'react';
import EXPLORE_CONSTANTS from "../../../Explore/utils/EXPLORE_CONSTANTS";
import Button from "../../../../components/Button";
import { exploreDashboard, get2DigitFormattedNumber, getAllDataFromPages } from '../../../../actions/commonActions';
import MainLoader from "../../../../components/Loader/MainLoader";
import { useInView } from "react-intersection-observer";
import MuiTabs from "../../../../components/MuiComponents/MuiTabs";
import { useUserManagement } from '../../../../hooks/dashboard';
import AllTableData from '../../../../components/Dashboard/AllTableData';
import Paper from "../../../../components/Dashboard/Paper";
import ProfilePic from '../../../../components/Common/ProfilePic';


const UsersList = ({ filterTitle }) => {
    const [tableState, setTableState] = useState(null)
    const userTabs = ["Customers", "Siemba"]
    const [tabs, setTabs] = useState(userTabs)
    const [tabIndex, setTabIndex] = useState(0)
    const [filter, setFilter] = useState(null)
    const [search, setSearch] = useState(null)

    const showFirstTab = tabIndex === 0
    const showSecondTab = tabIndex === 1

    const {
        data: userData,
        isFetching: isFetchingUser,
        fetchNextPage: fetchNextUser,
        hasNextPage: hasNextUser
    } = useUserManagement({
        search,
        userType: showFirstTab ? 'customer' : 'provider',
        sortBy: tableState?.sortBy,
        company_id: filter && filter.Company ? filter.Company : null,
        role: filter && filter.Access ? filter.Access : null,
        status: filter && filter.Status ? filter.Status : null,
    })

    const { ref: firstTabRef, inView: inViewFirstTab } = useInView()
    const { ref: secondTabRef, inView: inViewSecondTab } = useInView()

    useEffect(() => {

        fetchNextUser?.()

    }, [inViewFirstTab, inViewSecondTab])

    let userDetails = getAllDataFromPages(userData?.pages, "users") || [];
    let companyNames = getAllDataFromPages(userData?.pages, "companyNames") || [];


    const onTableChange = (tableState) => {
        setTableState(tableState)
    }

    const explore = (user_id, role) => {
        const currentActiveTab = tabIndex ? "siemba" : "customers"
        exploreDashboard(EXPLORE_CONSTANTS.USER_DETAILS, user_id, role, currentActiveTab, () => { })
    }

    let columns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: props => {
                const name = props?.value?.first_name.concat(" ", props?.value?.last_name)
                return (
                    <div className="inline-flex space-x-4 items-center">
                        <ProfilePic size={35} pic={props?.value?.profile_picture}
                            name={name} />

                        <span className="user-div"> {name}</span>

                    </div>
                )
            },
        },
        {
            Header: 'Email',
            //disableSortBy: true,
            accessor: 'email',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: props => {
                const status = props?.value?.status
                return (
                    <div className="inline-flex items-center">

                        <span className="user-div"> {status}</span>

                    </div>
                )
            },
        },
        {
            Header: 'Company',
            accessor: 'company',
            Cell: props => {
                const status = props?.value?.company
                return (
                    <div className="inline-flex items-center">

                        <span className="user-div"> {status}</span>

                    </div>
                )
            },
        },
        {
            Header: 'Access',
            accessor: 'access',
        },
        {
            Header: '',
            accessor: 'actions',
            disableSortBy: true,
            Cell: props => {
                const user_id = props?.value?.id;
                const role = props?.value?.role;
                return (
                    <>
                        <Button
                            rightArrow={true}
                            onClick={() => explore(user_id, role)} variant={"text"}
                            className={"!text-headings"}>EXPLORE</Button>
                    </>
                )
            },
            className: 'w-[130px]'
        },
    ]


    let tableData = userDetails?.map((item, index) => {
        return ({
            index: get2DigitFormattedNumber(index + 1),
            name: item,
            email: [item?.email],
            status: item,
            company: showFirstTab ? item : null,
            access: showFirstTab ? [customerMapping[item?.role]] : [providerMapping[item?.role]],
            actions: item,
        })
    })

    let hiddenColumns = []

    if (!showFirstTab)
        hiddenColumns = ["company"]

    const filterOptionsCustomer = [
        {
            name: "Company",
            type: "dropdown",
            filterValues: companyNames,
            firstFilter: true,
            multiSelector: true,
        },
        {
            name: "Status",
            type: "option",
            filterValues: ["Active", "Inactive", "Blocked"],
            multiSelector: true,
            secondFilter: true,
        },
        {
            name: "Access",
            type: "dropdown",
            filterValues:
                [
                    {
                        "name": customerMapping["customer_admin"],
                        "id": "customer_admin",
                    },
                    {
                        "name": customerMapping["customer_leader"],
                        "id": "customer_leader"
                    },
                    {
                        "name": customerMapping["pentest_manager"],
                        "id": "pentest_manager"
                    },
                    {
                        "name": customerMapping["pentest_overseer"],
                        "id": "pentest_overseer"
                    },
                    {
                        "name": customerMapping["asset_poc"],
                        "id": "asset_poc"
                    }

                ],
            multiSelector: true,
            lastFilter: true,
        }
    ]

    const filterOptionsSiemba = [
        {
            name: "Status",
            type: "option",
            filterValues: ["Active", "Inactive", "Blocked"],
            multiSelector: true,
            firstFilter: true,
            lastFilter: true
        },
        // {
        //     name: "Access",
        //     type: "dropdown",
        //     filterValues:
        //         [
        //             {
        //                 "name": providerMapping["siemba_admin"],
        //                 "id": "siemba_admin"
        //             },
        //             {
        //                 "name": providerMapping["customer_success_manager"],
        //                 "id": "customer_success_manager"
        //             },
        //             {
        //                 "name": providerMapping["siemba_pentest_lead"],
        //                 "id": "siemba_pentest_lead"
        //             },
        //             {
        //                 "name": providerMapping["siemba_tester"],
        //                 "id": "siemba_tester"
        //             },

        //         ],
        //     multiSelector: true,
        //     lastFilter: true,
        // }
    ]


    return (
        <div className="user-list">
            {isFetchingUser && <MainLoader />}
            <Paper >
                <div className="ml-4">
                    <MuiTabs className={"large"} varient={"fullWidth"} tabs={tabs}
                        value={tabIndex}
                        onChange={(e, value) => setTabIndex(value)}
                    />
                </div>
                <AllTableData
                    title={`${showFirstTab ? "All Customer Users" : "All Siemba Users"}`}
                    searchText={`${showFirstTab ? "Search for any name, company, status..." : "Search for any name, type, access..."}`}
                    description={`${showFirstTab ? "See the list of all customer users below" : "See the list of all siemba users below"}`}
                    columns={columns}
                    tableData={tableData}
                    dependencyArray={[userDetails]}
                    onTableChange={onTableChange}
                    onSearch={value => setSearch(value)}
                    tableClassName={"lg:!max-h-[calc(100vh-468px)]"}
                    filterTitle={filterTitle}
                    moreRef={showFirstTab ? firstTabRef : secondTabRef}
                    hasNextPage={hasNextUser}
                    hiddenColumns={hiddenColumns}
                    filterEnabled={true}
                    filterOptions={showFirstTab ? filterOptionsCustomer : filterOptionsSiemba}
                    setFilter={setFilter}
                    tabIndex={tabIndex}
                    setSearch={setSearch}
                />
            </Paper>
        </div>
    );
};


const customerMapping = {
    "asset_poc": "Point of Contact",
    "pentest_overseer": "Pentest Overseer",
    "pentest_manager": "Pentest Manager",
    "customer_leader": "Customer Leader",
    "customer_admin": "Customer Admin",
}

const providerMapping = {
    "siemba_tester": "Pentester",
    "siemba_pentest_lead": "Pentest Lead",
    "customer_success_manager": "Customer Success Manager",
    "customer_leader": "Customer Leader",
    "siemba_admin": "Siemba Admin",
}

export default UsersList;
