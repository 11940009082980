import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "../../components/Admin/Layout";
import {
    AllAssets,
    AllAssetsFilter, AllBlockers,
    AllFindings,
    AllFindingsFilter, AllHighRiskAssets, AllPentests, AllPentestsFilter, AssetDashboard, AssetDescAndUserStories, Chat,
    Dashboard, EditAssetScope, EnterpriseDashboard,
    FindingDashboard, PentestDashboard, PentestLandscape, PreRequisites
} from "../pages/PageListAsync";
import withPermissions from "../../hoc/withPermissions";
import { SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER } from "../../utils/roles";
import LayoutProvider from "../../Providers/LayoutProvider";

const MyDashboardRoutes = () => {
    return (
        <LayoutProvider value={{
            dashboard: "MY_DASHBOARD",
            mainUrl: "/app"
        }}>
            <Layout>
                <Routes>
                    <Route path={"/"} element={<Navigate to={"/app/dashboard"} />} />
                    <Route path={"/dashboard"} element={<Dashboard dashboard={"MY_DASHBOARD"} />} />
                    <Route path={"/enterprise_dashboard"} element={<EnterpriseDashboard />} />
                    {/*blockers*/}
                    <Route path={"/all_blockers"} element={<AllBlockers />} />
                    <Route path={"/dashboard/:company_id/all_blockers"} element={<AllBlockers />} />
                    {/*findings route*/}
                    <Route path={"/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_findings/:id/dashboard"} element={<FindingDashboard />} />
                    <Route path={"/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/dashboard/:company_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/dashboard/:company_id/all_findings/:filter"} element={<AllFindingsFilter />} />

                    {/*assets routes*/}
                    <Route path={"/all_assets"} element={<AllAssets />} />
                    <Route path={"/all_assets/:filter"} element={<AllAssetsFilter />} />
                    <Route path={"/all_assets/riskiest_assets"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/riskiest_assets/:pentest_id"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/:id/dashboard"} element={<AssetDashboard />} />
                    <Route path={"/all_assets/:id/edit_scope"} element={<EditAssetScope />} />
                    <Route path={"/all_assets/:id/asset_desc_and_user_stories"} element={<AssetDescAndUserStories />} />
                    <Route path={"/all_assets/:asset_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_assets/:asset_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/all_assets/:asset_id/pre_requisites"} element={<PreRequisites />} />
                    {/*pentests routes*/}
                    <Route path={"/all_pentests"} element={<AllPentests />} />
                    <Route path={"/pentests/:filter"} element={<AllPentestsFilter />} />
                    <Route path={"/all_pentests/:id/dashboard"} element={<PentestDashboard />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_pentests/:pentest_id/pentest_landscape"} element={<PentestLandscape />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/chat"} element={<Chat />} />
                    <Route path={"/chat/:conversation_id"} element={<Chat />} />
                </Routes>
            </Layout>
        </LayoutProvider>
    );
};

export default withPermissions(MyDashboardRoutes, { roles: [SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER] });
