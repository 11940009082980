import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { SIEMBAGPT_SESSIONS, SIEMBAGPT_SESSIONS_FINDINGS, SIEMBAGPT_SESSION_DETAILS } from "./utils/constants";

export const useGenPTSessions = (userId) => {
  return useQuery([SIEMBAGPT_SESSIONS, userId], async () => {
      let res = await axios.get(`/genpt/users/${userId}/sessions`);
      return res.data
  })
};

export const useGenPTSessionDetails = async (sessionId) => {
  return useQuery([SIEMBAGPT_SESSION_DETAILS, sessionId], async () => {
    let res = await axios.get(`/genpt/sessions/${sessionId}`);
    return res.data
})
};

export const useStartGenPTEngine = (options) => {
  return useMutation(async (data) => {
      let res = await axios.post(`/genpt/startTest`, data)
      console.log("response", res)
      return res.data
  }, options)
};

export const getSessionDetails = async (sessionId) => {
  try {
  const response = await axios.get(`/genpt/sessions/${sessionId}`);
  return response.data
  } catch (error) {
    console.error('Error scanning:', error);
  }
};

// export const useSessionFindings = async (sessionId) => {
//   console.log("sessionId",sessionId);
//   return useQuery([SIEMBAGPT_SESSIONS_FINDINGS, sessionId], async () => {
//     let res = await siembagpt.get(`/session/${sessionId}/findings`);
//     console.log("sessionFind", res)
//     return res.data
//   })
// };

export const fetchSessionFindings = async (sessionId) => {
  try{
    let res = await axios.get(`/genpt/sessions/${sessionId}/findings`);
    return res.data
  }catch (err){
    console.error('Error scanning:', err);
  }
};

export const fetchSessionFindingDetails = async (sessionId,findingId) => {
  try{
    let res = await axios.get(`/genpt/sessions/${sessionId}/findings/${findingId}`);
    return res.data
  }catch (err){
    console.error('Error scanning:', err);
  }
};

export const getLatestSessionActivity = async (sessionId, lastSessionActivityId) => {
  try {
    const response = await axios.get(`/genpt/sessions/${sessionId}/latestActivity?lastSessionActivityId=${lastSessionActivityId}`);
    return response.data
  } catch (error) {
    console.error('Error scanning:', error);
  }
};

export const getLatestScanResponse = async (sessionId, lastScanResponseId) => {
  try {
  const response = await axios.get(`/latestScan/${sessionId}?lastScanResponseId=${lastScanResponseId}`);
  return response.data
  } catch (error) {
    console.error('Error scanning:', error);
  }
};

export const getSessionResponses = async (sessionId) => {
  try {
  const response = await axios.get(`/genpt/sessions/${sessionId}/latestResponse`);
  return response.data
  } catch (error) {
    console.error('Error scanning:', error);
  }
};
