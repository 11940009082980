export const API_URL = process.env.REACT_APP_API_BASE_URL
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const CHAT_WEB_SOCKET_URL = process.env.REACT_APP_CHAT_WEB_SOCKET_URL
export const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID
export const PRODUCT_FRUITS_WORKSPACE_CODE = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE
export const IMPORT_ASSETS_TEMPLATE_KEY = process.env.REACT_APP_IMPORT_ASSETS_TEMPLATE_KEY
export const SIEMBAGPT_ENABLED = process.env.REACT_APP_SIEMBAGPT_ENABLED
/**
 * redirect to specefic routes based on customer profile status
 * @type {{pentest_review: string, pentest_profile: string, profile: string, pentest_team: string, pentest_scope: string, dashboard: string}}
 */
export const SIEMBA_CUSTOMER_STAGES_ROUTES = {
  "profile": "/profile",
  "pentest_profile": "/pentest/profile",
  "pentest_scope": "/pentest/scope",
  "pentest_team": "/pentest/team",
  "pentest_team_overseers": "/pentest/team_overseers",
  "pentest_team_asset_poc": "/pentest/team_asset_poc",
  "pentest_review": "/pentest/review",
  "dashboard": "/app",
}

export const DATE_FORMAT = "MM/DD/YY"

export const userTypes = {
  PROVIDER: 'provider',
  CUSTOMER: 'customer',
};

export const BLOCKER_STATUS = {
  OPEN: "open",
  CLOSED: "closed",
  RESOLVED: "resolved",
  REOPENED: "reopened"
}
