import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Typography from "../Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useNavigate} from "react-router-dom";

const Breadcrumbs = ({data, className}) => {
    let navigate = useNavigate()
    return (
        <div className={`mb-4 ${className}`}>
            {
                data?.map((item, index) => {
                    return (
                        <div key={index} className={"inline-block uppercase"}>
                            <Typography variant={"subtitle2"}
                                        className={`${index === data?.length - 1 && "text-headings"}`}>
                                <span onClick={() => item.url && navigate(item.url)}
                                      className={`${item.url && "cursor-pointer"}`}>{item.title}</span> {
                                ![data?.length - 1].includes(index) &&
                                <ArrowForwardIosIcon className={"!h-[14px] !w-[14px]"}/>} </Typography>
                        </div>
                    )
                })
            }
        </div>
    );
};

Breadcrumbs.propTypes = {
    data: PropTypes.array
};

export default memo(Breadcrumbs);
