import React, { Suspense } from "react";
import MainLoader from "../../components/Loader/MainLoader";

//Main Routes
const MainRoutes = {
    Home: React.lazy(() => import('./Home')),
    Profile: React.lazy(() => import('./Profile')),
    PentestProfile: React.lazy(() => import('./PentestProfile')),
    PentestScope: React.lazy(() => import('./PentestScope')),
    PentestTeam: React.lazy(() => import('./PentestTeam')),
    AssetPoc: React.lazy(() => import('./AssetPoc')),
    PentestReview: React.lazy(() => import('./PentestReview')),
    ConnectSiemba: React.lazy(() => import('./ConnectSiemba')),
    PentestCompany: React.lazy(() => import('./PentestCompany')),
    OktaCallback: React.lazy(() => import('./OktaCallback')),
    SiembaGPT: React.lazy(() => import('./SiembaGPT'))
}
// //Siemba Admin
const Admin = {
    Dashboard: React.lazy(() => import('./Admin/Dashboard')),
    MyCommunity: React.lazy(() => import('./Admin/MyCommunity')),
    MyCustomers: React.lazy(() => import('./Admin/MyCustomers')),
    MyTeam: React.lazy(() => import('./Admin/MyTeam')),
    UpdatePentest: React.lazy(() => import('./Admin/Dashboard/UpdatePentest')),
    SiembaGPTSession: React.lazy(() => import('./SiembaGPT/SiembaGPTSession')),
    SiembaGPTSessionFindings: React.lazy(() => import('./SiembaGPT/GPTSessionFindings'))
}
// My Dashboard
const MyDashboardRoutes = {
    Dashboard: React.lazy(() => import('./MyDashboard/Dashboard')),
    EnterpriseDashboard: React.lazy(() => import('./MyDashboard/EnterpriseDashboard')),
    AllFindings: React.lazy(() => import('./MyDashboard/AllFindings')),
    AllFindingsFilter: React.lazy(() => import('./MyDashboard/AllFindingsFilter')),
    FindingDashboard: React.lazy(() => import('./MyDashboard/FindingDashboard')),
    AllAssets: React.lazy(() => import('./MyDashboard/AllAssets')),
    AssetDashboard: React.lazy(() => import('./MyDashboard/AssetDashboard')),
    AllAssetsFilter: React.lazy(() => import('./MyDashboard/AllAssetsFilter')),
    AllHighRiskAssets: React.lazy(() => import('./MyDashboard/AllHighRiskAssets')),
    AllAssetsByTeam: React.lazy(() => import('./MyDashboard/AllAssetsByTeam')),
    AllPentestsFilter: React.lazy(() => import('./MyDashboard/AllPentestsFilter')),
    AllPentests: React.lazy(() => import('./MyDashboard/AllPentests')),
    AllPentestsByTeam: React.lazy(() => import('./MyDashboard/AllPentestsByTeam')),
    AllBlockers: React.lazy(() => import('./MyDashboard/AllBlockers')),
    PentestDashboard: React.lazy(() => import('./MyDashboard/PentestDashboard')),
    PentestLandscape: React.lazy(() => import('./MyDashboard/PentestLandscape')),
    EditAssetScope: React.lazy(() => import('./MyDashboard/EditAssetScope')),
    AssetDescAndUserStories: React.lazy(() => import('./MyDashboard/AssetDescAndUserStories')),
    PreRequisites: React.lazy(() => import('./MyDashboard/PreRequisites')),
    Chat: React.lazy(() => import('./MyDashboard/Chat')),
}
// Tester Dashboard
const TesterRoutes = {
    Dashboard: React.lazy(() => import('./Tester/Dashboard')),
}

export const Home = () => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.Home />
        </Suspense>
    );
};

export const SiembaGPT = () => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.SiembaGPT />
        </Suspense>
    );
};


export const SiembaGPTSession = () => {
    return (
        <Suspense fallback={<MainLoader />}>
            <Admin.SiembaGPTSession />
        </Suspense>
    );
};


export const SiembaGPTSessionFindings = () => {
    return (
        <Suspense fallback={<MainLoader />}>
            <Admin.SiembaGPTSessionFindings />
        </Suspense>
    );
};

export const OktaCallback = () => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.OktaCallback />
        </Suspense>
    );
};

export const Profile = () => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.Profile />
        </Suspense>
    );
};
export const Dashboard = ({ dashboard = 'ADMIN' }) => {
    return (
        <Suspense fallback={false}>
            {dashboard === 'ADMIN' && <Admin.Dashboard />}
            {dashboard === 'MY_DASHBOARD' && <MyDashboardRoutes.Dashboard />}
            {dashboard === 'TESTER_DASHBOARD' && <TesterRoutes.Dashboard />}
        </Suspense>
    );
};

export const AllFindings = ({ dashboard = 'ADMIN' }) => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllFindings />
        </Suspense>
    );
};

export const AllAssets = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllAssets />
        </Suspense>
    );
};

export const AllPentests = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllPentests />
        </Suspense>
    );
};

export const AllBlockers = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllBlockers />
        </Suspense>
    );
};

export const AllFindingsFilter = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllFindingsFilter />
        </Suspense>
    );
};

export const AllAssetsFilter = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllAssetsFilter />
        </Suspense>
    );
};

export const AllHighRiskAssets = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllHighRiskAssets />
        </Suspense>
    );
};

export const AllAssetsByTeam = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllAssetsByTeam />
        </Suspense>
    );
};

export const AllPentestsFilter = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllPentestsFilter />
        </Suspense>
    );
};

export const AllPentestsByTeam = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AllPentestsByTeam />
        </Suspense>
    );
};

export const FindingDashboard = (props) => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.FindingDashboard {...props} />
        </Suspense>
    );
};

export const PentestDashboard = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.PentestDashboard />
        </Suspense>
    );
};

export const UpdatePentest = () => {
    return (
        <Suspense fallback={false}>
            <Admin.UpdatePentest />
        </Suspense>
    );
};

export const AssetDashboard = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AssetDashboard />
        </Suspense>
    );
};

export const EditAssetScope = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.EditAssetScope />
        </Suspense>
    );
};

export const AssetDescAndUserStories = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.AssetDescAndUserStories />
        </Suspense>
    );
};

export const EnterpriseDashboard = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.EnterpriseDashboard />
        </Suspense>
    );
};

export const PreRequisites = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.PreRequisites />
        </Suspense>
    );
};

export const PentestLandscape = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.PentestLandscape />
        </Suspense>
    );
};

export const Chat = () => {
    return (
        <Suspense fallback={false}>
            <MyDashboardRoutes.Chat />
        </Suspense>
    );
};

export const MyCommunity = () => {
    return (
        <Suspense fallback={false}>
            <Admin.MyCommunity />
        </Suspense>
    );
};

export const MyCustomers = () => {
    return (
        <Suspense fallback={false}>
            <Admin.MyCustomers />
        </Suspense>
    );
};
export const MyTeam = () => {
    return (
        <Suspense fallback={false}>
            <Admin.MyTeam />
        </Suspense>
    );
};
export const PentestProfile = (props) => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.PentestProfile {...props} />
        </Suspense>
    );
};

export const PentestScope = (props) => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.PentestScope {...props} />
        </Suspense>
    );
};

export const PentestTeam = (props) => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.PentestTeam {...props} />
        </Suspense>
    );
};

export const AssetPoc = (props) => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.AssetPoc {...props} />
        </Suspense>
    );
};

export const PentestReview = (props) => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.PentestReview {...props} />
        </Suspense>
    );
};

export const ConnectSiemba = () => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.ConnectSiemba />
        </Suspense>
    );
};

export const PentestCompany = (props) => {
    return (
        <Suspense fallback={<MainLoader />}>
            <MainRoutes.PentestCompany {...props} />
        </Suspense>
    );
};
