import React, {useContext, useEffect, useRef, useState} from 'react'
import { getAllSession, getLatestScanResponse, getLatestSessionActivity, getSessionDetails, startGPTEngine, useGenPTSessions } from '../../../hooks/siembagpt'
import styles from "../../../components/Dashboard/styles.module.scss";
import stylesAdmin from "../Admin/Dashboard/dashboard.module.scss";
import { Link, useNavigate, useParams } from 'react-router-dom'
import pluralize from "pluralize";
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import ReactTable from "../../../components/Table";
// import { clearGPTSession, setGPTSession } from '../../../redux/reducers/dashboard'
import { useValidateLogin } from '../../../hooks/auth'
import { LayoutContext } from '../../../Providers/LayoutProvider'
import moment from 'moment'
import { get2DigitFormattedNumber } from '../../../actions/commonActions'
import Paper from '../../../components/Dashboard/Paper'
import MainLoader from '../../../components/Loader/MainLoader';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';

function SiembaGPT() {
    const { data: userData } = useValidateLogin()
    const [tableState, setTableState] = useState(null)
    const navigate = useNavigate()
    // const [GPTSessions, setSessions] = useState(null)
    const {data: GPTSessions, isFetching} = useGenPTSessions(userData?.user?.id);
    console.log("GPTSessions", GPTSessions)
    let GPTSessionsList = GPTSessions?.sessions || []
    // const fetchSessions = async () => {
    //     try {
    //         const response = await getAllSession(userData?.id);
    //             //   setLastSessionActivityId(response)
    //         if (response) {
    //             setSessions(response);
    //         }   
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    //   };
    
    //   useEffect(() => {
    //     fetchSessions()
    //   }, []);

    const changeTableState = (tableState) => {
        setTableState(tableState)
    }

    const {mainUrl} = useContext(LayoutContext)
    const columns = [
        {
            Header: '#',
            accessor: 'index',
            className: 'w-14',
            disableSortBy: true
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: props => {
                const [value, id] = props.value
                return (
                    <Link className={"link"} to={`${mainUrl}/genpt/test/${id}`}>
                        {value}
                    </Link>
                )
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            className: 'w-[140px]',
            Cell: props => {
                let value = props?.value;
                return (
                    <div className={"capitalize"}>{value}</div>
                )
            }
        },
        {
            Header: 'Start Date',
            accessor: 'start_date',
            className: 'w-[140px] th-align-center',
            Cell: props => (
                <div className={"capitalize text-center"}>
                    <p>{moment(props?.value).format("DD/MM/YYYY")}</p>
                    <p className='text-xs text-body'>{moment(props?.value).format("hh:mm:ss A")}</p>
                </div>)
        },
        {
            Header: 'End Date',
            accessor: 'end_date',
            className: 'w-[140px] th-align-center',
            Cell: props => {
                const [status, time] = props.value
                if (status === 'completed') {
                    return(
                        <div className={"capitalize text-center"}>
                            <p>{moment(time).format("DD/MM/YYYY")}</p>
                            <p className='text-xs text-body'>{moment(time).format("hh:mm:ss A")}</p>
                        </div>
                    )            
                } else if (status === 'inprogress') {
                    return(
                        <div className='text-center'>
                            <p>--</p>
                        </div>
                    )
                }                        
            }
        },
        {
            Header: 'Test Time',
            accessor: 'test_time',
            className: 'w-[120px] th-align-center',
            Cell: props => {
                const [status, createdAt, updatedAt] = props.value

                const sessionTime = (startTime, endTime) => {
                    if(startTime && endTime) {
                        var duration = moment.duration(endTime.diff(startTime));
                        const hours = duration.hours();
                        const minutes = duration.minutes();
                        const seconds = duration.seconds();
                
                        const pad = (num) => (num < 10 ? `0${num}` : num);
                
                        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
                    }
                }

                if (status === 'completed') {
                    return(
                        <div className={"capitalize text-center"}>
                            {sessionTime(moment(createdAt), moment(updatedAt))}
                        </div>
                    )            
                } else if (status === 'inprogress') {
                    return(
                        <div className='text-center'>
                            <p>--</p>
                        </div>
                    )
                }                        
            }
        },
        {
            Header: '',
            accessor: 'actions',
            disableSortBy: true,
            className: 'w-[100px]',
            Cell: props => {
                const item = props?.value
                return (
                    <>
                        <Button onClick={() => navigate(`${mainUrl}/genpt/test/${item?._id}`)} variant={"text"}
                            rightArrow={true}
                            className={"whitespace-nowrap !text-headings"}>Expand</Button>
                    </>
                )
            },
        },
    ]
    let tableData = GPTSessionsList?.map((item, index) => ({
        index: get2DigitFormattedNumber(index + 1),
        name: [item?.session_name, item?._id],
        status: item?.status,
        start_date: item?.createdAt,
        end_date: [item?.status, item?.updatedAt],
        test_time: [item?.status, item?.createdAt,item?.updatedAt],
        actions: item,
    }))


    // const [currentSession, setCurrentSession] = useState(null)
    // const dispatch = useDispatch()
    // const {GPTSession} = useSelector(state => state.dashboard)

    // const fetchSessionDetails = async() => {
    //     try {
    //         const sessionDetails = await getSessionDetails(sessionId);
    //         const scanResponses = await getLatestScanResponse(sessionId, '');
    //         const sessionActivities = await getLatestSessionActivity(sessionId, '');
    //         // setCurrentSession(sessionDetails)
    //         if(sessionId && sessionDetails){
    //             const GPTSession = {...sessionDetails, scanResponses: scanResponses, sessionActivities: sessionActivities}
    //             // dispatch(setGPTSession(
    //             //     GPTSession
    //             // ))
    //         }
    //     } catch (error) {
            
    //     }
    // }

    // useEffect(() => {
    //     if(sessionId) fetchSessionDetails()
    // }, []);

    // useEffect(() => {
    //     // dispatch(clearGPTSession())
    //     if(sessionId){
    //         const intervalId = setInterval(fetchSessionDetails(), 600000); // 60 seconds
        
    //         // Clean up the interval when the component unmounts or when sessionId changes
    //         return () => clearInterval(intervalId);
    //     } 
    // }, [sessionId]);

    return (
        <div id={"gpt"} className='mt-8'>
        <div className={`${stylesAdmin.overview} h-full`}>
            <div className={stylesAdmin.background}></div>
            <div className={"flex"}>
                <div className={"flex-1"}>
                    <Typography variant={"h5"}>You have a total of <span
                        className={"text-secondary-900 font-bold"}>{get2DigitFormattedNumber(GPTSessionsList?.length)} {pluralize('Test', GPTSessionsList?.length)}</span></Typography>
                </div>
                <div className={`mx-2`}>
                <Button onClick={() => navigate(`${mainUrl}/genpt/test/`)} fullWidth={true} size={"small"} variant={"contained"}
             className={`!bg-secondary-700`}><AddIcon /> New Test</Button>
                </div>
                <div>
                </div>
            </div>
        </div>
        <Paper>
             {isFetching && <MainLoader/>}
                <div className={`${styles.data} !max-h-[calc(100vh-340px)]`}>
                    <ReactTable
                        data={tableData}
                        columns={columns}
                        className={"table lg:table-fixed"}
                        headerClassName={"background rounded"}
                        bodyClassName={"text-headings"}
                        dependencyArray={[GPTSessionsList]}
                        manualSortBy={true}
                        onTableChange={changeTableState}
                    />
                </div>
        </Paper>
        </div>
    )
}
export default SiembaGPT

