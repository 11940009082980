import React from 'react';
import {useImage} from "../../hooks/misc";

const Image = ({src, ...rest}) => {
    let url = useImage(src)
    return (
        <img src={url} {...rest} alt=""/>
    );
};

export default Image;
