export const findingStatus = {
    potential: {
        title: "Potential",
        color: "var(--functional-blue-600)",
    },
    open: {
        title: "Open",
        color: "var(--functional-red-900)",
    },
    passed: {
        title: "Passed",
        color: "var(--functional-blue-600)",
    },
    failed: {
        title: "Failed",
        color: "var(--functional-blue-600)",
    },
    // info: {
    //     title: "Info",
    //     color: "var(--functional-blue-600)",
    // },
    retest_ready: {
        title: "Retest Ready",
        color: "var(--functional-blue)",
    },
    retest_failed: {
        title: "Retest Failed",
        color: "var(--functional-red-700)",
    },
    rejected: {
        title: "Rejected",
        color: "var(--functional-blue-50)",
    },
    remediated: {
        title: "Remediated",
        color: "var(--success-green)",
    },
    risk_accepted: {
        title: "Risk Accepted",
        color: "var(--functional-orange)",
    },
    risk_transferred: {
        title: "Risk Transferred",
        color: "var(--functional-yellow)",
    },
    passed: {
        title: "Passed",
        color: "var(--success-green)",
    },
    failed: {
        title: "Failed",
        color: "var(--functional-red-900)",
    },
    // info: {
    //     title: "Info",
    //     color: "var(--functional-purple)",
    // }
}

// export const likelihoodStatus = {
//     low: {
//         title: "Low",
//         status: "low"
//     },
//     medium: {
//         title: "Medium",
//         status: "medium"
//     },
//     high: {
//         title: "High",
//         status: "high"
//     },
//     critical: {
//         title: "Critical",
//         status: "critical"
//     },
//     info: {
//         title: "Informational",
//         status: "info"
//     }
// }

export const likelihoodStatus = {
    veryLikely: {
        title: "Very Likely",
        status: "veryLikely"
    },
    likely: {
        title: "Likely",
        status: "likely"
    },
    possible: {
        title: "Possible",
        status: "possible"
    },
    unlikely: {
        title: "Unlikely",
        status: "unlikely"
    },
    veryUnlikely: {
        title: "Very Unlikely",
        status: "veryUnlikely"
    }
}

export const severityStatus = {
    low: {
        title: "Low",
        status: "low"
    },
    medium: {
        title: "Medium",
        status: "medium"
    },
    high: {
        title: "High",
        status: "high"
    },
    critical: {
        title: "Critical",
        status: "critical"
    },
    info: {
        title: "Info",
        status: "info"
    }
}

export const findingColors = {
    critical: {
        name: "Critical",
        color: "var(--functional-red-900)",
        value: 100
    },
    high: {
        name: "High",
        color: "var(--functional-red-700)",
        value: 75
    },
    medium: {
        name: "Medium",
        color: "var(--functional-orange)",
        value: 50
    },
    low: {
        name: "Low",
        color: "var(--functional-yellow)",
        value: 25
    },
    info: {
        name: "Info",
        color: "var(--functional-blue-50)",
        value: 12.5
    }
}

export const alertFindingsStatus = [
    'open',
    'retest_ready',
    'retest_failed'
]

export const closedFindingsStatus = [
    'remediated',
    'rejected',
    'risk_accepted',
    'risk_transferred'
]
